import { buildCollection } from "firecms";

export const requestsCollection = buildCollection({
  path: "requests",
  name: "Requests",
  singularName: "Request",
  properties: {
    created_at: {
      name: "Creado el",
      validation: { required: false },
      dataType: "date"
    },
    startDate: {
      name: "Fecha de Incorporación",
      validation: { required: false },
      dataType: "date"
    },
    name: {
      name: "Nombre",
      validation: { required: false },
      dataType: "string"
    },
    surname: {
      name: "Apellidos",
      validation: { required: false },
      dataType: "string"
    },
    status: {
      name: "Estado",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        PENDING: "PENDIENTE",
        APPROVED: "APROBADO",
        REJECTED: "RECHAZADO"
      }
    },
    position: {
      name: "Puesto de Trabajo",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        CTO: "CTO"
      }
    },
    department: {
      name: "Departamento",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        CTO: "CTO"
      }
    },
    manager: {
      name: "Manager Responsable",
      validation: { required: false },
      dataType: "string"
    },
    company: {
      name: "Razón Social del Grupo",
      validation: { required: false },
      dataType: "string"
    },
    center: {
      name: "Centro de Contratación",
      validation: { required: false },
      dataType: "string"
    },
    address: {
      name: "Dirección de la Sede",
      validation: { required: false },
      dataType: "string"
    },
    hostname: {
      name: "Hostname de la Cuenta",
      validation: { required: false },
      dataType: "string"
    },
    domain: {
      name: "Dominio Principal",
      validation: { required: false },
      dataType: "string"
    },
    secondaryDomain: {
      name: "Dominio Secundario",
      validation: { required: false },
      dataType: "string"
    },
    license: {
      name: "Tipo de Licenciamiento O365",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        A3: "MICROSOFT O365 A3"
      }
    },
    profile: {
      name: "Perfil de Usuario",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        COMERCIAL: "COMERCIAL",
        CALL_CENTER: "CALL CENTER",
        MK: "MK",
        ADMINISTRACION: "ADMINISTRACION",
        ETC: "ETC"
      }
    },
    applications: {
      name: "Aplicaciones a Instalar",
      validation: { required: false },
      dataType: "string"
    },
    hardware: {
      name: "Necesidades de Hardware",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        LENOVO_THINKBOOK: "LENOVO THINKBOOK 14 GEN6 + IPHONE 14",
        HP_PROBOOK_I5: "PORTATIL HP PROOBOOK I5",
        HP_PROBOOK_I7: "PORTATIL HP PROOBOOK I7",
        HP_E23_G4: "PANTALLA HP E23 G4",
        USB_C_G5: "USB-C G5 ESSENTIAL DOCK",
        POLY_BLACKWIRE_3220: "CASCOS POLY BLACKWIRE 3220",
        TARGUS_USB_C_HDMI_VGA: "TARGUS USB-C HDMI-VGA - DOCK419C",
        XIAOMI_REDMI_10C: "MOVIL XIAOMI REDMI 10C",
        SAMSUNG_A54: "MOVIL SAMSUNG A54",
        SAMSUNG_A14: "MOVIL SAMSUNG A14",
        IPHONE_14: "MOVIL IPHONE 14"
      }
    },
    userName: {
      name: "Nombre del Usuario para la Gestión",
      validation: { required: false },
      dataType: "string"
    },
    contactNumber: {
      name: "Número de Contacto",
      validation: { required: false },
      dataType: "string"
    },
    additionalEmail: {
      name: "Correo Adicional",
      validation: { required: false },
      dataType: "string"
    },
    deliveryAddress: {
      name: "Lugar de Entrega",
      validation: { required: false },
      dataType: "string"
    }
  }
});