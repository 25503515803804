import { FirestoreTextSearchController } from "firecms";
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";

const norm = (value) => {
  const from = "àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþßàáâãäåæçèéêëìíîïðñòóôõöøùúûýýþÿŕŕ";
  const to = "aaaaaaaceeeeiiiidnoooooouuuuybsaaaaaaaceeeeiiiidnoooooouuuyybyrr";

  let aux = value || ''
  for (let i = 0; i < from.length; i++) {
    const char_re = new RegExp(from.charAt(i), "gim");
    aux = aux.toLowerCase().replace(char_re, to.charAt(i))
  };

  return aux.replace(/[^\w\s]/gi, ' ').split(' ').filter(Boolean).join(' ').toLowerCase();
}

const search = async (path: string, terms: string[]): Promise<string[]> => {
  try {
    // Get a Firestore instance
    const db = getFirestore();

    // Perform the search for the terms "enfermería" and "madrid"
    const searchQuery = query(
      collection(db, "index"),
      where("doc", "==", path),
      where("keywords", "array-contains-any", terms)
    );
    const searchResults = await getDocs(searchQuery);

    // Extract document IDs from the search results
    const documentRefs: any = {};
    const documentIds: string[] = [];

    searchResults.forEach(doc => {
      const ref = doc.data().doc
      documentRefs[ref] = documentRefs[ref] || []
      documentRefs[ref].push(doc.data().id);
      documentIds.push(doc.data().id);
    });

    const results: any = []
    const p = Object.entries(documentRefs).map(async ([ref, ids]) => {
      const promises = ids.map(async (id) => {
        const docRef = doc(db, ref, id)
        const docData = await getDoc(docRef)
        results.push({
          ...docData.data(),
          objectID: docData.id
        })
      })
      await Promise.all(promises)
    })

    await Promise.all(p)
    
    return documentIds
  } catch (error) {
    console.error("Error performing search:", error);
    return [];
  }
}

export const textSearchController: FirestoreTextSearchController = ({
  path,
  searchString
}) => {
  const whole = norm(searchString).split(' ')
  return search(path, whole);
}


