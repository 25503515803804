import { EntityCallbacks, buildCollection } from "firecms";
import { centros, sedes } from "./enums";

type Autoassignment = {
  sede: string[] | string;
  rules: any[];
  autoassignment: string[] | string;
  exact: boolean;
}
const toArray = (element: string[] | string) => {
  return Array.isArray(element) ? element : [element]
}
const defaultSedes = (sede: string) => ({
  hygiea: 'Saius'
})[sede] || sede

export const autoassignmentsCallbacks: EntityCallbacks<Autoassignment> = {
  onFetch: ({ entity }) => {
    const sede = (entity.values.sede || [])
    toArray(sede).map(item => {
      sedes[item] = defaultSedes(item);
    })
    return entity
  }
};

export const autoassignmentsCollection = buildCollection<Autoassignment>({
  path: "autoassignments",
  alias: "aaa",
  callbacks: autoassignmentsCallbacks,
  name: "Autoassignments",
  singularName: "Autoassignment",
  group: "Main",
  icon: "AssignmentTurnedIn",
  description: "Collection of autoassignments",
  textSearchEnabled: false,
  // default sort
  initialSort: ['sede', 'asc'],
  // initialFilter: { sede: ['array-contains-any', ['test']] },
  // forceFilter: { sede: ['array-contains-any', ['test']] },
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: Boolean(authController.extra?.roles?.admin)
  }),
  properties: {
    sede: {
      dataType: "array",
      name: "Sede",
      description: "Sede",
      validation: {
        required: true
      },
      of: {
        name: 'Sedes',
        dataType: 'string',
        validation: {
          required: true
        },
        enumValues: sedes
      }
    },
    rules: {
      // hideFromCollection: true,
      name: 'Rule',
      dataType: 'array',
      oneOf: {
        typeField: 'type',
        valueField: 'value',
        properties: {
          id: {
            dataType: "array",
            name: "Id",
            description: "Id",
            validation: {
              required: false
            },
            of: {
              dataType: "string"
            }
          },
          centro: {
            dataType: "array",
            name: "Centro",
            description: "Centro",
            validation: {
              required: false
            },
            of: {
              dataType: "string"
            }
          },
          mode: {
            dataType: "array",
            name: "Mode",
            description: "Mode",
            validation: {
              required: false
            },
            of: {
              dataType: "string"
            }
          },
          from: {
            dataType: "array",
            name: "From",
            description: "From",
            validation: {
              required: false
            },
            of: {
              dataType: "string"
            }
          },
          category: {
            dataType: "array",
            name: "Category",
            description: "Category",
            validation: {
              required: false
            },
            of: {
              dataType: "string"
            }
          },
          utm_source: {
            dataType: "array",
            name: "Utm Source",
            description: "Utm Source",
            validation: {
              required: false
            },
            of: {
              dataType: "string"
            },
          },
          utm_estrategia: {
            dataType: "array",
            name: "Utm Estrategia",
            description: "Utm Estrategia",
            validation: {
              required: false
            },
            of: {
              dataType: "string"
            },
          },
          latino: { // Boolean
            dataType: "boolean",
            name: "Latino",
            description: "Es latino",
            validation: {
              required: false
            }
          },
          estudio: {
            dataType: "array",
            name: "Estudio",
            description: "Estudio",
            validation: {
              required: false
            },
            of: {
              dataType: "string"
            },
          },
        },
      },
    } as any,
    autoassignment: {
      dataType: "array",
      name: "Autoassignment",
      of: {
        dataType: "string"
      },
      description: "List of autoassigned users",
      validation: {
        required: true
      }
    },
    // priority: {
    //   dataType: "number",
    //   name: "Priority",
    //   description: "Priority",
    //   validation: {
    //     required: false
    //   }
    // },
    exact: {
      dataType: "boolean",
      name: "Match exactly",
      description: "Match exactly",
      validation: {
        required: false
      }
    }
  }
});
