import React from "react";
import { PropertyPreviewProps } from "firecms";
import Box from "@mui/material/Box";

const formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR"
});

export default function PriceTextPreview({
  value,
  property,
  size,
  customProps,
  entity
}: PropertyPreviewProps<number>) {

  // 300.99 => 300,99 €
  const price = (value: any) => {
    if (property && property.name && property.name.includes('Descuento')) {
      return parseFloat(value) + " %"
    }
    if (value)
      return formatter.format(value);
    else return null
  }
  console.log({property, customProps, entity})
  return (
    <Box
      sx={{
        fontSize: value ? undefined : "small",
        color: value ? undefined : "#838383",
        fontWeight: "bold"
      }}>
      {price(value)}
    </Box>
  );

};
